// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
    @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
    @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
    @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}
.kt-header-menu-dark {
    @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";


@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

.btn-xs {
  padding: 0.2rem 0.7rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn.btn-icon.btn-xs, .btn-group-xs > .btn.btn-icon {
  height: 2rem;
  width: 2rem;
}

.cell-action{
  width:1%;
  white-space:nowrap;
}

.center-div{
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  height: 100%;
}

.font25{
  font-size : 2.5rem;
}
.font14{
  font-size: 14px;
}
.font13{
  font-size: 13px;
}
.font12{
  font-size: 12px;
}

.dropdown-toggle.no-caret:after { content: none }

.btn-square{
  border-radius: 0;
}

.m0{
  margin: 0;
}

.mr10{
  margin-right: 10px;
}

.mr5{
  margin-right: 5px;
}

.ml10{
  margin-left: 10px;
}

.vmiddle{
  vertical-align: middle;
}

.spanevent{
  display: block;
  padding: 4px;
  text-align: center;
  margin-bottom: 5px;
  min-height: 24px;
  margin-left: 1px;
  margin-right: 1px;
}
.spanevent.spanevent-primary{
  background: #3699ff;
  color: #fff;
}
.spanevent.spanstart {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.spanevent.spanend {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.spanevent.pending {
  background: #ffa800;
  color: #fff;
}
.spanevent.approved {
  background: #1bc5bd;
  color: #fff;
}
.spanevent.denied {
  background: #f64e60;
  color: #fff;
}
.event-hour-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 40px;
  .event-hour-box{
    flex: 1;
    height: 100%;
    margin-right: 1px;
    margin-left: 1px;
    .event-hour-start{
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .event-hour-end{
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .event-hour-box:last-child{
    margin-left: 0;
    margin-right: 1px;
  }
}

td .spanevent:last-child{
  margin-bottom: 0;
}

.bb0{
  border-bottom: none !important;
}
.blgray{
  border-left: 1px solid rgba(224, 224, 224, 1) !important;
}
.brgray{
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
}
.h40{
  height: 40px !important;
}
.fw600{
  font-weight: 600 !important;
}
.p0{
  padding: 0 !important;
}
.hidden{
  display: none !important;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  background-color: rgb(117, 217, 204) !important;
}